export const dockFiveSixFormTextObj = {
  dockType: '设备类型',
  selectedDockFile: '生成文件类型',
  dock6FDrill_towSideConHole: '通孔分双面打通',
  dock56FDrill_twoSideConSlot: '通槽分双面打通',
  non_holes_not_files: '矩形板没有孔槽不输出文件',
  front_file_identifier: '正面文件标识',
  opposite_file_identifier: '反面文件标识',
  arc_description: '圆弧描述方式',
  split_slot: '宽槽拆解为多条窄槽加工',
  single_width: '拆解后单条槽宽度',
  output56FDrillLayoutDir: '板件长边摆放方向',
  is_output56FDrillLayoutDir: '板件长边摆放方向选择框',
  selectedOutput56DrillSoltDir: '五/六面钻槽或异形放置方向选择框',
  output56FDrillSlotDir: '五/六面钻槽或异形放置方向',
  output56FDrillDecimalNum: '坐标值保留位数',
  is_front_edge_fixed: '前封边摆放方向',
  front_edge_fixed_direction: '固定在',
  isNotMergeSlot: '相邻的等宽等深槽拉通处理',
  adjacentSize: '相邻尺寸小于等于',
  long_edge_rotate_range: '板件长边≤',
  automation: '桦桦自动线',

  output5FDrillSlotKnifeCode: 'XML2铣槽刀具编号',
  output5FDrillShapeKnifeCode: 'XML2铣型刀具编号',
  output5FDrillNormalTexdirCode: 'XML2板件纹理-横纹=',
  output5FDrillReverseTexdirCode: 'XML2板件纹理-竖纹=',
  output5FDrillShapeKnifeOffset: 'XML2铣型刀具补偿',

  dock6FDrill_code: 'ban格式文件包含Code信息',
  utf8bom: '输出bom格式文件',
  dock6FDrill_plankHeight: '板件高度描述',
  dock6FDrill_plankCode: '板号描述',
  dock6FDrill_zValue: '反面槽Z坐标',
  dock6FOutputMprFile: '文件类型',
  dock56FDrillMprConHoleDir: 'mpr侧面孔Z坐标基准面',
}

export const dockFiveSixFormValueObj = {
  arc_description: {
    line_points: '线段成型描述',
    arc_command: '圆弧指令描述',
  },
  output56FDrillLayoutDir: {
    Horizontal: '横向',
    Vertical: '纵向',
  },
  output56FDrillSlotDir: {
    default: '系统默认',
    frontLeft: '前和左',
    frontRight: '前和右',
    backLeft: '后和左',
    backRight: '后和右',
  },
  dock6FDrill_zValue: {
    0: '-(板厚-槽深)',
    1: '-(槽深)',
  },
  front_edge_fixed_direction: {
    left: '左边',
    right: '右边',
    front: '前边',
    back: '后边',
  },
  // dock6FOutputMprFile: {
  //   mpr: '旧版MPR',
  //   newMpr: '新版MPR',
  // },
}
